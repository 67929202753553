// This module has left over code not being used. Render methods have been moved to patagonia-sfra/patagonia-sfra/storefront-reference-architecture/cartridges/app_storefront_base/cartridge/client/default/js/components/search.js

import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

// module configuration data

import ControllerRaf from '../../../assets/controllers/controller-raf/script';
import {
    bindEvents,
    multiAddEventListener,
    multiRemoveEventListener,
} from '../../../assets/helpers';

// Import Bloomreach Helpers
import { blrTrackEvent } from '../../../../../../../../int_bloomreach_patagonia/cartridge/client/default/js/helpers/blrHelpers';

const blrEnabled = window?.patagonia?.clientDataModel?.bloomreach?.pixelEnabled;
const catalog = window?.patagonia?.clientDataModel?.bloomreach?.catalog;

/**
 * @description get popular search terms
 */
const getPopularSearchTerms = () => {
    const el = document.querySelector('[data-search-popular-endpoint]');
    const endpoint = el.dataset.searchPopularEndpoint;
    $.ajax({
        url: endpoint,
        method: 'GET',
        success: (response) => {
            el.innerHTML = response;
        },
    });
};

/**
 * @description get personalized recently viewed products
 */
const getProductsViewed = () => {
    const el = document.querySelector('[data-search-recently-viewed-endpoint]');
    const endpoint = el.dataset.searchRecentlyViewedEndpoint;
    const $searchOverlay = $('.search-overlay');

    $.ajax({
        url: endpoint,
        method: 'GET',
        success: (response) => {
            $(el).html(response);
            $searchOverlay.removeClass('is-first-in is-default is-default-in');
            $searchOverlay.addClass('is-results is-results-in');
        },
    });
};

export const selector = 'search-overlay';
export const highCharQueryVisibleText = 'field-search__high-char-count';

export default class SearchOverlay {
    constructor(section, options) {
        this.el = {
            section,
            closeX: section.querySelector(`.${selector}__close-cta`),
            clearQuery: section.querySelector(`.${selector}__clear-query`),
            queryField: section.querySelector(`.${selector}__query-field`),
            queryFieldForm: section.querySelector(`.${selector}__query-field form`),
            queryFieldInput: section.querySelector(`.${selector}__query-field input`),
            queryFieldWrapper: section.querySelector(`.${selector}__query-field-wrapper`),
            suggestionWrapper: section.querySelector(`.${selector}__suggestion-wrapper`),
            suggestionHeader: section.querySelector(`.${selector}__suggestion-header`),
            suggestions: section.querySelector(`.${selector}__scrolling-wrapper`),
            categoriesHeader: section.querySelector(`.${selector}__categories-header`),
            categoriesItemsWrapper: section.querySelector(`.${selector}__categories-items-wrapper`),
            categoriesItems: section.querySelectorAll(`.${selector}__categories-items-wrapper li`),
            categoriesItemsButtons: section.querySelectorAll(
                `.${selector}__categories-items-wrapper li a`
            ),
            categoriesWrapper: section.querySelector(`.${selector}__categories`),
            results: section.querySelector(`.${selector}__results`),
            resultsHeaderCount: section.querySelector(`.${selector}__results-query-header-count`),
            resultsProductsWrapper: section.querySelector(`.${selector}__results-products-wrapper`),
            resultsProducts: section.querySelectorAll(`.${selector}__results-products-wrapper li`),
            resultsMoreCta: section.querySelector(`.${selector}__results-more-cta`),
            resultsMoreCtaHeading: section.querySelector(
                `.${selector}__results-more-cta .cta-rounded-rect__heading`
            ),
            noResults: section.querySelector(`.${selector}__no-results`),
            noResultsQueryHeadingSpan: section.querySelector(
                `.${selector}__no-results-query-heading span`
            ),
            initialSearchOverlayScrollingWrapper: section.querySelector(
                '.js-search-overlay__scrolling-wrapper__initial'
            ),
        };

        this.globalEl = {
            triggerButtons: document.body.querySelectorAll('[data-search-overlay-trigger]'),
        };

        // default parameters to be merged with provided options
        this.defaults = {
            fewResultsCount: 3,
            maxResultsCount: 12,
        };

        // merge default options with param options
        this.options = {
            ...this.defaults,
            ...options,
        };

        // class variables

        this.coords = {
            toggleScrollY: 0,
            queryFieldWrapperHeight: 0,
        };

        // boolean flag states
        this.flags = {
            isShowing: false,
            isDefaultResults: true,
            isFetchingResults: false,
            isNoResults: false,
            isTypeAhead: this.el.section.dataset.typeAhead,
            isSearchSuggestion: this.el.section.dataset.searchSuggestions,
        };

        // queued actions
        this.queued = {
            renderResults: null,
        };

        // observers
        this.observers = {
            intersection: null,
        };

        // ajax requests
        // formatted as { xhrQuery, queryString }
        this.requests = {
            search: null,
        };

        // module states
        this.states = {
            queryString: null,
            resultsString: null,
            suggestionString: null,
            resultsArrayString: null,
            typeAheadMinChar: this.el.section.dataset.typeAheadStart,
            suggestionsMinimumChar: this.el.section.dataset.searchSuggestionsStart,
            termSuggestFetch: null,
        };

        // throttled methods
        this.throttled = {
            checkInput: throttle(this.checkInput.bind(this), 240, {
                leading: true,
            }),
            toggleClearBtn: throttle(this.toggleClearBtn.bind(this), 240, {
                leading: true,
            }),
        };

        // timeouts
        this.timeouts = {
            collapse: null,
            results: null,
        };

        // bind event scope
        this.events = bindEvents(this);

        // Initial dom innerhtml
        this.initialDOMHTML = '';

        // let's roll
        this.init();
    }

    init() {
        this.addEventListeners();
        this.saveInitialSearchDom();
        this.observeForInitialResultsChildChanges();
        this.observeForInitialTermsChanges();
    }

    // event listeners

    addEventListeners() {
        this.el.closeX.addEventListener('click', this.events._onCloseClick);
        this.el.clearQuery.addEventListener('click', this.events._onClearQuery);
        this.el.suggestions.addEventListener('click', this.events._onSuggestClick);
        this.el.queryFieldForm.addEventListener('submit', this.events._onQuerySubmit);
        this.el.queryFieldInput.addEventListener('input', this.events._onQueryInput);
        this.el.queryFieldInput.addEventListener('change', this.events._onQueryChange);
        this.el.queryFieldInput.addEventListener('keydown', (e) => this.events._onKeyPress(e));
        this.el.section.addEventListener(
            'scroll',
            debounce(this.events._onScroll.bind(this), 10, {
                leading: false,
                trailing: true,
            }),
            {
                passive: true,
            }
        );

        window.addEventListener(
            'resize',
            debounce(this.events._onWindowResize.bind(this), 10, {
                leading: false,
                trailing: true,
            })
        );

        if (this.globalEl.triggerButtons) {
            multiAddEventListener(
                this.globalEl.triggerButtons,
                'click',
                this.events._onTriggerClick
            );
        }

        this.el.section.addEventListener('keyup', (e) => {
            if (e.defaultPrevented) {
                return;
            }
            const key = e.key || e.keyCode;
            if (key === 'Escape' || key === 'Esc' || key === 27) {
                this.events._onCloseClick();
            }
        });
    }

    removeEventListeners() {
        this.el.closeX.removeEventListener('click', this.events._onCloseClick);
        this.el.clearQuery.removeEventListener('click', this.events._onClearQuery);
        this.el.suggestions.addEventListener('click', this.events._onSuggestClick);
        this.el.queryFieldForm.removeEventListener('submit', this.events._onQuerySubmit);
        this.el.queryFieldInput.removeEventListener('input', this.events._onQueryInput);
        this.el.queryFieldInput.removeEventListener('keydown', this.events._onKeyPress);
        this.el.section.removeEventListener(
            'scroll',
            debounce(this.events._onScroll.bind(this), 10, {
                leading: false,
                trailing: true,
            })
        );

        window.removeEventListener(
            'resize',
            debounce(this.events._onWindowResize.bind(this), 10, {
                leading: false,
                trailing: true,
            })
        );

        if (this.globalEl.triggerButtons) {
            multiRemoveEventListener(
                this.globalEl.triggerButtons,
                'click',
                this.events._onTriggerClick
            );
        }
    }

    // listener methods

    _onQueryInput() {
        this.throttled.checkInput();
        this.throttled.toggleClearBtn();
    }

    _onQueryChange() {
        this.checkInput();
    }

    _onQuerySubmit() {
        if (blrEnabled) {
            const searchData = {
                q: this.el.queryFieldInput.value,
                catalogs: [{ name: catalog }],
            };

            blrTrackEvent('suggest', 'submit', searchData);
        }
    }

    _onAnchorClick() {
        this.rafHide();
    }

    _onCloseClick() {
        this.hide();
    }

    _onClearQuery() {
        this.el.queryFieldInput.value = '';
        this.toggleClearBtn();
        this.resetResults();
    }

    _onSuggestClick(e) {
        const { target } = e;
        const targetTag = target.tagName;

        if (
            (targetTag === 'SPAN' || targetTag === 'A' || targetTag === 'STRONG') &&
            target?.closest('ul')?.classList?.contains('search-overlay__suggestions-phrase-list')
        ) {
            const query = target.parentElement.innerText;

            if (blrEnabled) {
                const searchData = {
                    aq: this.el.queryFieldInput.value,
                    q: query,
                    catalogs: [{ name: catalog }],
                };

                blrTrackEvent('suggest', 'click', searchData);
            }
        }
    }

    _onResultsClick() {
        this.rafHide();
    }

    _onTriggerClick() {
        this.show();
    }

    _onScroll() {
        this.checkScroll();
    }

    _onWindowResize() {
        this.checkResize();
    }

    _onKeyPress(e) {
        this.onTabPress(e);
    }
    // show / hide

    show() {
        getPopularSearchTerms();
        getProductsViewed();
        this.toggleClearBtn();

        if (!this.flags.isShowing) {
            this.flags.isShowing = true;

            this.coords.toggleScrollY = window.scrollY;
            document.body.classList.add('is-fixed');
            document.body.style.top = `-${this.coords.toggleScrollY}px`;

            this.el.section.classList.add('active', 'is-init');
            this.el.queryFieldInput.focus();

            window.scrollTo(0, 0);

            clearTimeout(this.timeouts.collapse);
            this.timeouts.collapse = null;

            ControllerRaf.one(() => {
                this.el.section.classList.add('is-in', 'is-first-in');
                this.el.section.classList.remove('is-init');
            });
        }
    }

    hide() {
        if (this.flags.isShowing) {
            this.flags.isShowing = false;
            document.body.classList.remove('is-fixed');
            document.body.style.top = '';

            window.scrollTo({
                top: this.coords.toggleScrollY,
            });

            this.el.section.classList.add('is-out');

            this.killObservers();

            clearTimeout(this.timeouts.collapse);
            this.timeouts.collapse = setTimeout(() => {
                this.el.section.classList.remove(
                    'is-in',
                    'is-out',
                    'active',
                    'is-first-in',
                    'is-default',
                    'is-default-in',
                    'is-results',
                    'is-results-in',
                    'is-few-results'
                );
                this.el.queryFieldInput.blur();
                this.el.queryFieldInput.value = '';
                this.resetResults();
                this.timeouts.collapse = null;
            }, 600);
        }
    }

    rafHide() {
        ControllerRaf.one(() => {
            this.hide();
        });
    }

    // Save initial loaded content
    saveInitialSearchDom() {
        this.initialDOMHTML = this.el.initialSearchOverlayScrollingWrapper.innerHTML;
    }

    observeForInitialResultsChildChanges() {
        const targetCategoryResultsNode = document.getElementById('searchOverlayResults');
        targetCategoryResultsNode.classList.add('results__is-empty');
        const searchOverlayCategoryResultsConfig = {
            childList: true,
            subtree: true,
        };
        const _this = this;
        const searchOverlayCategoryResultsCallback = function (
            mutationsList,
            searchOverlayCategoryResultsObserver
        ) {
            mutationsList.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    targetCategoryResultsNode.classList.remove('results__is-empty');
                    document
                        .querySelector('.search-overlay')
                        .classList.remove('search-overlay--no-initial-results');
                    _this.saveInitialSearchDom();
                    searchOverlayCategoryResultsObserver.disconnect();
                }
            });
        };
        const searchOverlayCategoryResultsObserver = new MutationObserver(
            searchOverlayCategoryResultsCallback
        );
        searchOverlayCategoryResultsObserver.observe(
            targetCategoryResultsNode,
            searchOverlayCategoryResultsConfig
        );
    }

    observeForInitialTermsChanges() {
        const targetCategoryTermsNode = document.querySelector(
            '.js-search-overlay__scrolling-wrapper__initial'
        );
        const searchOverlayCategoryTermsConfig = {
            childList: true,
            subtree: true,
        };
        const _this = this;
        let hasSaved = false;
        const searchOverlayCategoryTermsCallback = function (mutationsList) {
            mutationsList.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    if (hasSaved === false) {
                        _this.saveInitialSearchDom();
                        hasSaved = true;
                    } else if (
                        targetCategoryTermsNode.querySelector('.search-overlay__results') ===
                            null ||
                        (targetCategoryTermsNode.querySelector('.search-overlay__results') &&
                            targetCategoryTermsNode
                                .querySelector('.search-overlay__results')
                                .classList.contains('results__is-empty'))
                    ) {
                        document
                            .querySelector('.search-overlay')
                            .classList.add('search-overlay--no-initial-results');
                    } else {
                        document
                            .querySelector('.search-overlay')
                            .classList.remove('search-overlay--no-initial-results');
                    }
                }
            });
        };
        const searchOverlayCategoryTermsObserver = new MutationObserver(
            searchOverlayCategoryTermsCallback
        );
        searchOverlayCategoryTermsObserver.observe(
            targetCategoryTermsNode,
            searchOverlayCategoryTermsConfig
        );
    }

    // Disable default tab behavior only if search field has focus and suggestion hasn't been filled
    onTabPress(e) {
        if (
            e.keyCode === 9 &&
            document.querySelector('.suggestion') &&
            document.activeElement === document.querySelector('.search-field') &&
            this.states.suggestionString !== null
        ) {
            e.preventDefault();
            document.querySelector('.search-field').value = this.states.suggestionString;
        }
    }

    // computations from scroll and resize events
    checkResize() {
        const lastQueryFieldWrapperHeight = this.coords.queryFieldWrapperHeight;
        const wrapperStyles = getComputedStyle(this.el.queryFieldWrapper);
        const wrapperPadding = parseInt(wrapperStyles.getPropertyValue('padding-top'), 10);
        this.coords.queryFieldWrapperHeight =
            wrapperPadding * (2 + this.el.queryField.offsetHeight);

        if (
            !this.observers.intersection ||
            lastQueryFieldWrapperHeight !== this.coords.queryFieldWrapperHeight
        ) {
            this.killObservers();
            this.observers.intersection = new IntersectionObserver(this.events._onObserverUpdate, {
                rootMargin: `-${
                    this.coords.queryFieldWrapperHeight + wrapperPadding
                }px 0px 0px 0px`,
                threshold: 0,
            });
            this.observers.intersection.observe(this.el.categoriesWrapper);
        }
    }

    // observer updates

    _onObserverUpdate(entries) {
        for (let i = 0; i < entries.length; i += 1) {
            const entry = entries[i];
            if (entry.target === this.el.categoriesWrapper) {
                if (entry.isIntersecting) {
                    this.el.section.classList.add('is-scroll-categories');
                } else {
                    this.el.section.classList.remove('is-scroll-categories');
                }
            }
        }
    }

    killObservers() {
        if (this.observers.intersection) {
            this.observers.intersection.disconnect();
            this.observers.intersection = null;
        }
    }

    // evaluable input

    checkInput() {
        const inputValue = this.el.queryFieldInput.value;
        if (
            this.flags.isSearchSuggestion === 'true' &&
            inputValue.length <= this.states.suggestionsMinimumChar - 1
        ) {
            if (
                !document.body.contains(
                    document.querySelector('.js-search-overlay__categories__initial')
                )
            ) {
                this.resetResults();
            }
        }
    }

    resetResults() {
        this.el.section.classList.remove('is-results', 'is-results-in', 'is-few-results');
        this.el.section.classList.add('is-first-in');
        this.el.initialSearchOverlayScrollingWrapper.innerHTML = this.initialDOMHTML;
    }

    showNoResults() {
        if (!this.flags.isNoResults) {
            this.flags.isNoResults = true;
            this.el.section.classList.remove(
                'is-results',
                'is-results-in',
                'is-default',
                'is-default-in',
                'is-few-results',
                'is-no-results-in'
            );
            this.el.section.classList.add('is-no-results');

            ControllerRaf.one(() => {
                this.el.section.classList.add('is-no-results-in');
            });
        }

        this.el.noResultsQueryHeadingSpan.innerHTML = `${this.states.queryString}`;
    }

    hideNoResults() {
        if (this.flags.isNoResults) {
            this.flags.isNoResults = false;
            this.el.section.classList.remove('is-no-results', 'is-no-results-in');
        }
    }

    toggleClearBtn() {
        const queryInput = this.el.queryFieldInput.value;
        this.el.clearQuery.classList.toggle('d-none', queryInput.length === 0);
    }

    // destroy

    destroy() {
        document.body.classList.remove('no-scroll');
        this.removeEventListeners();
    }
}
