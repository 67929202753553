import defaultsDeep from 'lodash/defaultsDeep';
import debounce from 'lodash/debounce';
import setClasses from 'modernizr-esm/src/setClasses';
import positionsticky from 'modernizr-esm/feature/css/positionsticky';
import $ from 'jquery';
import App from './assets/app.js';
import { processInclude } from './util';
import initPolyfills from './assets/polyfills';
import LibraryDISImage from './components/dis-image/library-dis-image';
import ProductTileImage from './components/product/product-tile-image/script';
import HeaderStoreLocation from './components/header-store-location/storeLocation';
import { init } from './_init.js';
import { init as initSession } from './_initPage.js';
import { initListeners as initGTMListeners } from './helpers/gtmHelpers.js';

window.$ = $;
window.jQuery = window.$;

const root = document.documentElement;

setClasses([`${positionsticky ? '' : 'no-'}positionsticky`]);

const stickyHeaderUtils = () => {
    const header = document.querySelector('[data-navigation-primary]');
    if (header) {
        const visiblePartOfHeader = header.clientHeight - 1;
        root.style.setProperty('--pat-header-nav-height', `${visiblePartOfHeader / 10}rem`);
    }
    const bannerTop = document.querySelector('.utility-bar');
    if (bannerTop) {
        root.style.setProperty('--pat-utility-bar-height', `${bannerTop.clientHeight / 10}rem`);
    }
};

const detectIOSVersion = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isMacLike = navigator.userAgent.includes('Intel Mac OS X'); // for iPad >= 13 which uses Mac user agents
    const isChrome = navigator.userAgent.includes('Chrome');

    if (isIOS || (isMacLike && !isChrome)) {
        const match =
            navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/) ||
            navigator.userAgent.match(/Mac OS X (\d+)_(\d+)_?(\d+)?/);

        if (match) {
            const majorVersion = parseInt(match[1], 10);
            return majorVersion;
        }
    }
    return null;
};

const initCustomElements = () => {
    if (customElements.get('library-dis-image')) {
        return;
    }
    customElements.define('library-dis-image', LibraryDISImage);
    customElements.define('product-tile-image', ProductTileImage);
    customElements.define('header-store-location', HeaderStoreLocation);

    import('./modules/modals/modal-video/script').then(({ default: ModalVideo }) => {
        customElements.define('modal-video', ModalVideo);
    });

    import('./modules/modals/modal-scroll-email-signup/script').then(
        ({ default: ModalScrollEmailSignup }) => {
            customElements.define('modal-scroll-email-signup', ModalScrollEmailSignup);
        }
    );

    import('./modules/animation/lottie-animation/script').then(({ default: LottieAnimation }) => {
        customElements.define('lottie-animation', LottieAnimation);
    });

    import('./components/ModalStickyHeader').then(({ default: ModalStickyHeader }) => {
        customElements.define('modal-sticky-header', ModalStickyHeader);
    });

    import('./modules/sliders/minimal-product').then(({ default: MinimalProductCarousel }) => {
        customElements.define('minimal-product-carousel', MinimalProductCarousel);
    });

    import('./modules/sliders/product-list').then(({ default: ProductListCarousel }) => {
        customElements.define('product-list-carousel', ProductListCarousel);
    });
    import('./modules/routing-tile-flex-layout').then(({ default: routingTileFlexLayout }) => {
        customElements.define('routing-tile-flex-layout', routingTileFlexLayout);
    });

    import('./modules/marketing-grid-layout').then(({ default: marketingGridLayout }) => {
        customElements.define('marketing-grid-layout', marketingGridLayout);
    });

    import('./components/content-asset-body-loader').then(({ default: ContentAssetBodyLoader }) => {
        customElements.define('content-asset-body-loader', ContentAssetBodyLoader);
    });

    import('./modules/back-to-top').then(({ default: BacktoTop }) => {
        customElements.define('back-to-top', BacktoTop);
    });

    $(document).ready(() => {
        import('./components/marketing-tile-caption').then(({ default: MarketingTileCaption }) => {
            customElements.define('marketing-tile-caption', MarketingTileCaption);
        });
    });
};

const runScripts = () => {
    window.patagonia = window.patagonia || {};
    window.patagonia.app = new App();

    init();
    initSession();
    initCustomElements();

    $(document).ready(() => {
        processInclude(require('base/components/menu'));
        processInclude(require('base/components/cookie'));
        processInclude(require('base/components/consentTracking'));
        processInclude(require('base/components/footer'));
        processInclude(require('./product/recommendations'));

        import(/* webpackPreload: true */ './cart/cart').then(({ default: cartInit }) => {
            cartInit();
        });

        processInclude(require('base/components/collapsibleItem'));
        processInclude(require('./components/search'));
        processInclude(require('./components/countrySelector'));
        processInclude(require('./components/loginLinks'));
        processInclude(require('./loginModal')); // should come before /components/modals
        processInclude(require('./components/modals'));
        processInclude(require('./components/customInput'));
        processInclude(require('./components/toolTip'));
        processInclude(require('./components/popover'));

        // lower priority
        import('./emailSignUp/emailSubscriptionSignUp').then(
            ({ default: emailSubscriptionSignUp }) => {
                emailSubscriptionSignUp();
            }
        );

        // defer execution for performance
        import('./content').then(({ default: content }) => {
            processInclude(content);
        });

        processInclude(require('./logout'));
        processInclude(require('./components/megamenu'));
        processInclude(require('./util/extensions'));

        // None of these need to trigger immediately, these all require user action
        import(/* webpackFetch: true */ './plugin/instorepickup/stores/availableStores').then(
            ({ default: availableStoresMethods }) => {
                processInclude(availableStoresMethods);
            }
        );

        processInclude(require('./components/accordionAccessibility'));

        // initialize global events
        import('./util/helpers').then(({ default: helpers }) => {
            helpers.globalEvents();
        });

        // init all modules that might be on the page
        window.patagonia.app.initControllers();

        import('./util/validator').then(({ default: validator }) => {
            validator.init();
        });

        import('./util/phoneFormatting').then(({ default: phoneFormatting }) => {
            phoneFormatting.init();
        });

        initGTMListeners();

        import('./app/tealium/video').then(({ default: VideoReporting }) => {
            if ($('[data-video]').length) {
                $('[data-video]')
                    .toArray()
                    /* eslint-disable-next-line */
                    .map(function (el) {
                        new VideoReporting(el);
                    });
            }
        });

        import('./app/tealium/tealium.js').then(({ default: tealium }) => {
            // global Tealium event auto tracking
            tealium.captureClick(
                'a[data-tealium-event], button[data-tealium-event], div[data-tealium-event]',
                tealium.collect
            );
            tealium.captureForm(tealium.collectSearchData);

            tealium.exitCampaignTrack();

            function tealiumRefineData() {
                const tealiumRefineDataElems = document.querySelectorAll('.tealiumRefineData');
                tealiumRefineDataElems.forEach((elem) => {
                    const dataAttr = elem.dataset.tealiumData;
                    const eventAttr = elem.dataset.tealiumEvent;
                    try {
                        const data = JSON.parse(dataAttr);
                        window.utag_data = defaultsDeep(window.utag_data, data);
                        debounce(() => tealium.collect(eventAttr, data), 1000);
                    } catch (e) {
                        console.warn(e);
                    }
                    elem.remove();
                });
            }

            document.addEventListener('tealiumRefineData', tealiumRefineData);
            tealiumRefineData();
        });

        const wornwearEnabled = window.patagonia.clientDataModel?.wornWear?.enabled || false;

        if (wornwearEnabled) {
            // init ww modal
            import('./wornwear/wornwear').then(({ default: wornwear }) => {
                wornwear.init();
            });

            // init trade in modal
            import('./tradein/tradein').then(({ default: tradein }) => {
                tradein.init();
            });
        }

        // Page specific imports since jquery.validate will load jquery twice
        if (document.querySelector('.page-request-catalog')) {
            import('./_catalog').then(({ default: catalog }) => {
                catalog();
            });
        }

        if (document.querySelector('.product-detail')) {
            import('./_productDetail').then(({ default: productDetail }) => {
                productDetail();
            });
        }

        if (document.querySelector('.contact-page')) {
            import('./_contact').then(({ default: contactPage }) => {
                contactPage();
            });
        }

        if (document.querySelector('#checkout-main')) {
            import('./_checkout').then(({ default: checkoutApp }) => {
                checkoutApp();
            });
            import('./_paymentProviders').then(({ default: paymentProviders }) => {
                paymentProviders();
            });
        }

        if (document.querySelector('.repair-main')) {
            import('./_repair').then(({ default: repairApp }) => {
                repairApp();
            });
        }

        if (document.querySelector('#repair-landing')) {
            import('./_repairLanding').then(({ default: repairLandingApp }) => {
                repairLandingApp();
            });
        }

        if (document.querySelector('.navigation-subnav-content')) {
            import('./_contentSubNav').then(({ default: contentSubNav }) => {
                contentSubNav();
            });
        }

        if (document.querySelector('.search-results-content-grid--drop-in')) {
            import('./modules/grid/grid-content-drop-in/script').then(
                ({ default: gridContentDropIn }) => {
                    gridContentDropIn();
                }
            );
        }

        processInclude(require('./affiliateTracking'));
    });

    require('./components/spinner');
};

function boot() {
    stickyHeaderUtils();

    const iosVersion = detectIOSVersion();
    if (iosVersion && iosVersion <= 15) {
        document.body.classList.add('is-ios15-or-lower');
    }

    if (iosVersion && iosVersion <= 13) {
        document.body.classList.add('is-ios13-or-lower');
    }

    window.addEventListener('resize', stickyHeaderUtils);
    if (!window.bootstrap) {
        import(/* webpackPreload: true */ 'bootstrap').then((bootstrap) => {
            window.bootstrap = bootstrap;
            new Promise((resolve) => {
                initPolyfills(resolve);
            }).finally(runScripts);
        });
    } else {
        // Needed for SB HMR
        runScripts();
    }
}

boot();
