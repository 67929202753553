import debounce from 'lodash/debounce';
import { initProductTiles } from './productTile';
import { loadRatingsForNewTiles } from '../search/ratings';
import { gtmPushEvents } from '../helpers/gtmHelpers.js';

const endpoint = $('.suggestions-wrapper').data('url');
// eslint-disable-next-line no-unused-vars
let productLength = 0;
const minChars = $('.search-overlay').data('searchSuggestionsStart');
const suggestionInit = $('.search-overlay').data('searchSuggestions');
let currentRequest = null;

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).parents('.search-overlay').find('.search-overlay__scrolling-wrapper');
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch(scope)) {
        $('body').addClass('modal-open');
        getSuggestionsWrapper(scope).find('.suggestions').addClass('modal');
    }
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    const mobileSearchIcon = '.search-mobile span.';
    const iconSearch = 'fa-search';
    const iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch)
            .removeClass(iconSearch)
            .addClass(iconSearchClose);
    } else {
        $(mobileSearchIcon + iconSearchClose)
            .removeClass(iconSearchClose)
            .addClass(iconSearch);
    }
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if ($(scope).scrollTop() + $(scope).innerHeight() >= $(scope)[0].scrollHeight) {
        $('.more-below').fadeOut();
    } else {
        $('.more-below').fadeIn();
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    let outerHeight;
    let $scope;
    let $suggestions;
    let top;

    if (isMobileSearch(scope)) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        $suggestions = getSuggestionsWrapper(scope).find('.suggestions');
        $suggestions.css('top', top + outerHeight);

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}

/**
 * Render results
 *
 *
 */
function renderResults() {
    const $searchOverlay = $('.search-overlay');
    $searchOverlay.removeClass('is-first-in is-default is-default-in');
    $searchOverlay.addClass('is-results is-results-in');

    productLength = Math.floor($('[data-product-length]').attr('data-product-length'));

    // bold matching search terms
    if ($('.search-overlay__categories-items-wrapper').find('strong').length === 0) {
        const query = document.querySelector('.search-overlay__query-field input').value;
        const queryRegex = new RegExp(`(${query})`, 'ig');
        $('.search-overlay__categories-items-wrapper')
            .find('span.h7')
            .each((num, elem) => {
                let text = $(elem).text();
                text = text.replace(queryRegex, '<strong>$&</strong>');
                $(elem).html(text);
            });
        // adding term bolding for suggestions
        const suggestionListItems = document.querySelectorAll(
            '.search-overlay__suggestions-phrase-list li'
        );

        suggestionListItems.forEach((li) => {
            const formattedQuery = query.charAt(0).toUpperCase() + query.slice(1);
            const span = li.querySelector('span');
            let text = span.textContent;
            const split = text.split(' ');
            const clean = split.filter((a) => a !== formattedQuery);
            clean.forEach((cl) => {
                text = text.replace(cl, '<strong>$&</strong>');
            });
            span.innerHTML = text;
        });
    }
    // set 'is-few-results' class (or not)
    if (productLength > minChars) {
        $searchOverlay.removeClass('is-few-results');
    } else {
        $searchOverlay.addClass('is-few-results');
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    if (!(typeof response === 'object')) {
        const $suggestionsWrapper = getSuggestionsWrapper(this).empty();
        $suggestionsWrapper.append(response);

        // update the suggestion in the input field
        const $suggestion = $('.suggestion');
        const $phrase = $(response).find('.suggestion__phrase');
        const $term = $(response).find('.suggestion__term');

        if ($phrase.length > 0) {
            const $suggestionString = $phrase.html().trim();
            const $termString = $term.html().trim();
            if (
                $suggestion.length > 0 &&
                $suggestion.html().trim() !== $suggestionString &&
                $suggestionString.indexOf($termString) === 0
            ) {
                $suggestion.html($suggestionString.split($termString).join(''));
            }
        }

        positionSuggestions(this);
        renderResults(response);
        if (isMobileSearch(this)) {
            toggleSuggestionsIcon('close');
            applyModals(this);
        }
        initProductTiles($suggestionsWrapper[0]);
        loadRatingsForNewTiles();

        if (window.gtmSearchedItems) {
            gtmPushEvents(window.gtmSearchedItems);
            delete window.gtmSearchedItems;
        }
    }
}

/**
 * Enable/Disable Search Submit
 *
 * @param {Object} scope - Search field DOM element
 */
function updateSearchButton(scope) {
    if ($(scope).val().length > 1) {
        $(scope).siblings('.search-submit').prop('disabled', false).removeClass('disabled');
    } else {
        $(scope).siblings('.search-submit').prop('disabled', true).addClass('disabled');
    }
}

/**
 * Enable/Disable Search Submit
 *
 * @param {Object} scope - Search field DOM element
 */
function updateSearchButtonCheck(scope) {
    // for when there's already a value in the search field
    updateSearchButton($(scope));

    // for when the user starts typing in the search field
    $(scope).on('keyup', () => {
        updateSearchButton($(scope));
    });
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        currentRequest = $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            beforeSend() {
                if (currentRequest != null) {
                    currentRequest.abort();
                }
            },
            success: processResponse,
            error() {},
        });
    } else {
        // eslint-disable-next-line no-lonely-if
        if (currentRequest != null) {
            currentRequest.abort();
        }
    }

    updateSearchButtonCheck(scope);
}

export default function search() {
    if (suggestionInit === true) {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        const debounceSuggestions = debounce(getSuggestions, 300);
        let isSearchOverlay;

        $('input.search-field').each(function () {
            isSearchOverlay = $(this).parents('.search-overlay').length > 0;
            let selectedLI = -1;

            if (isSearchOverlay) {
                this.addEventListener('input', (event) => {
                    const { inputType } = event;
                    const { isComposing } = event;

                    // trigger on keyboard autosuggest selection or finished keyboard typing
                    if (inputType === 'insertFromComposition' || !isComposing) {
                        debounceSuggestions(this, event);
                    }
                });

                $(this).bind('keydown', function (e) {
                    const keyCode = e.keyCode || e.which;
                    const suggestionsListItems = $('.search-overlay__suggestions-phrase-list  li');
                    const hasSuggestions = suggestionsListItems.length > 0;

                    if (keyCode === 9) {
                        // tab key
                        const $phrase =
                            $('.suggestion__phrase').length > 0
                                ? $('.suggestion__phrase').html().trim()
                                : null;
                        if ($phrase) {
                            $(this).val($phrase);
                        }
                    }
                    // cycle though suggestions with up or down arrows
                    if (hasSuggestions) {
                        if (e.code === 'ArrowDown') {
                            if (selectedLI >= 0) {
                                suggestionsListItems[selectedLI].classList.remove('selected');
                            }

                            selectedLI += 1;

                            if (selectedLI === suggestionsListItems.length) {
                                selectedLI = 0;
                            }

                            suggestionsListItems[selectedLI].classList.add('selected');
                            $(this).val(suggestionsListItems[selectedLI].textContent);
                        }
                        if (e.code === 'ArrowUp') {
                            suggestionsListItems[selectedLI].classList.remove('selected');

                            if (selectedLI > 0) {
                                selectedLI -= 1;
                            } else {
                                selectedLI = suggestionsListItems.length - 1;
                            }

                            suggestionsListItems[selectedLI].classList.add('selected');
                            $(this).val(suggestionsListItems[selectedLI].textContent);
                        }

                        if (
                            e.code === 'Enter' &&
                            !e.target.classList.contains('field-search__input') // prevent firing on form submit
                        ) {
                            $(this).val(suggestionsListItems[selectedLI].textContent);
                        }
                    }
                });
            } else {
                updateSearchButtonCheck(this);
            }
        });
    }
}
